import { useEffect, useMemo, useState } from 'react';

import usePricing from '@/hooks/pricing/usePricing';
import { SubscriptionDataModel } from '@/models/Payments';
import { SubscriptionPlanModel } from '@/models/Subscription';

export const subscriptionPerks = [
  {
    description: 'SUBSCRIPTION.PERKS.DISCOUNTS-ON-PLAYLISTS',
    icon: 'check',
    isNew: false,
  },
  {
    description: 'SUBSCRIPTION.PERKS.RUN-UNLIMITED-ADS-WITH-NO-BOOKING-FEE',
    icon: 'check',
    isNew: false,
  },
  {
    description: 'SUBSCRIPTION.PERKS.CREATE-YOUR OWN-WEBSITE',
    icon: 'check',
    isNew: true,
  },
  {
    description: 'SUBSCRIPTION.PERKS.TAKE-MARKETING-ACTIONS',
    icon: 'check',
    isNew: false,
  },
  {
    description: 'SUBSCRIPTION.PERKS.ONE-TO-ONE-SUPPORT',
    icon: 'check',
    isNew: false,
  },
  {
    description: 'SUBSCRIPTION.PERKS.PLUS-MORE',
    icon: 'check',
    isNew: false,
  },
];

export const subscriptionPerksFull = [
  {
    description: 'SUBSCRIPTION.FULL-PERKS.PERK-1',
    icon: 'check',
    isNew: false,
  },
  {
    description: 'SUBSCRIPTION.FULL-PERKS.PERK-2',
    icon: 'check',
    isNew: true,
  },
  {
    description: 'SUBSCRIPTION.FULL-PERKS.PERK-3',
    icon: 'check',
    isNew: false,
  },
  {
    description: 'SUBSCRIPTION.FULL-PERKS.PERK-4',
    icon: 'check',
    isNew: false,
  },
  {
    description: 'SUBSCRIPTION.FULL-PERKS.PERK-5',
    icon: 'check',
    isNew: false,
  },
  {
    description: 'SUBSCRIPTION.FULL-PERKS.PERK-6',
    icon: 'check',
    isNew: false,
  },
  {
    description: 'SUBSCRIPTION.FULL-PERKS.PERK-7',
    icon: 'check',
    isNew: false,
  },
  {
    description: 'SUBSCRIPTION.FULL-PERKS.PERK-8',
    icon: 'check',
    isNew: false,
  },
];

export const subscriptionPerksCancellation = [
  {
    description: 'SUBSCRIPTION.CANCELATION-PERKS.PERK-1',
    icon: 'check',
    isNew: false,
  },
  {
    description: 'SUBSCRIPTION.CANCELATION-PERKS.PERK-2',
    icon: 'check',
    isNew: false,
  },
  {
    description: 'SUBSCRIPTION.CANCELATION-PERKS.PERK-3',
    icon: 'check',
    isNew: true,
  },
  {
    description: 'SUBSCRIPTION.CANCELATION-PERKS.PERK-4',
    icon: 'check',
    isNew: false,
  },
  {
    description: 'SUBSCRIPTION.CANCELATION-PERKS.PERK-5',
    icon: 'check',
    isNew: false,
  },
  {
    description: 'SUBSCRIPTION.CANCELATION-PERKS.PERK-6',
    icon: 'check',
    isNew: false,
  },
  {
    description: 'SUBSCRIPTION.CANCELATION-PERKS.PERK-7',
    icon: 'check',
    isNew: false,
  },
  {
    description: 'SUBSCRIPTION.CANCELATION-PERKS.PERK-8',
    icon: 'check',
    isNew: false,
  },
];

export const usePlans = () => {
  const { subscriptionData, isDataLoading } = usePricing();
  const [plans, setPlans] = useState<SubscriptionPlanModel[]>([]);

  const subscriptionPlans = useMemo(() => {
    return [
      {
        title: 'SUBSCRIPTION.PLANS.ANNUALLY-2-MONTHS-FREE',
        value: 'annually',
        price: (subscriptionData as SubscriptionDataModel[])?.filter((item) => item.period === 'yearly')[0]?.price,
        billed: 'SUBSCRIPTION.PLANS.BILLED-ANNUALLY',
        description: 'SUBSCRIPTION.PLANS.PER-MONTH',
        pricePerMonth: (subscriptionData as SubscriptionDataModel[])?.filter((item) => item.period === 'yearly')[0]
          ?.pricePerMonth,
        priceId: (subscriptionData as SubscriptionDataModel[])?.filter((item) => item.period === 'yearly')[0]
          ?.stripePriceId,
      },
      {
        title: 'SUBSCRIPTION.PLANS.STANDARD-PLAN',
        value: 'monthly',
        price: (subscriptionData as SubscriptionDataModel[])?.filter((item) => item.period === 'monthly')[0]?.price,
        billed: 'SUBSCRIPTION.PLANS.BILLED-MONTHLY',
        description: '',
        pricePerMonth: (subscriptionData as SubscriptionDataModel[])?.filter((item) => item.period === 'monthly')[0]
          ?.pricePerMonth,
        priceId: (subscriptionData as SubscriptionDataModel[])?.filter((item) => item.period === 'monthly')[0]
          ?.stripePriceId,
      },
    ] as SubscriptionPlanModel[];
  }, [subscriptionData]);

  useEffect(() => {
    if (!subscriptionData) return;
    setPlans(subscriptionPlans);
  }, [subscriptionData, subscriptionPlans]);

  return {
    plans: plans,
    isLoading: isDataLoading,
    subscriptionData: subscriptionData,
  };
};
