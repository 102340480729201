export const RegEx = {
  youtubeRegEx: new RegExp(
    '@"A<iframes*width=""560""s*height=""315""s*src=""https://www.youtube.com/embed/.+""s*title=""YouTube video player""s*frameborder=""0""s*allow=""accelerometer;s*autoplay;s*clipboard-write;s*encrypted-media;s*gyroscope;s*picture-in-picture;s*web-share""s*allowfullscreen></iframe>z"'
  ),
  urlRegEx: new RegExp(
    '(https?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|https?://(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,}|www.[a-zA-Z0-9]+.[^s]{2,})'
  ),
  spotifyRegEx: new RegExp('((http|https):\\/\\/)?(open\\.spotify\\.com\\/artist\\/).*'),
  socialPlatforms: {
    instagram: new RegExp('^@[a-zA-Z0-9._]{1,30}$'), // Letters, numbers, underscores, periods; 1-30 chars
    tiktok: new RegExp('^@[a-zA-Z0-9_.]{2,24}(?<!\\.)$'), // No trailing period; 2-24 chars
    facebook: new RegExp('^(?=.{5,50}$)[a-zA-Z0-9.]+$'), // Min 5 chars, no consecutive or trailing periods
    youtube: new RegExp('^[a-zA-Z0-9_-]{3,30}$'), // Approximate for custom usernames; 3-30 chars
    twitter: new RegExp('^@[a-zA-Z0-9_]{4,15}$'), // Letters, numbers, underscores; 4-15 chars
    snapchat: new RegExp('^[a-zA-Z][a-zA-Z0-9_]{2,14}$'), // Starts with letter; 3-15 chars
    discord: new RegExp('^[\\w]{2,32}$'), // Letters, numbers, underscore; 2-32 chars  },
  },
};
