export const Images = {
  playlistIcon: '/images/logos/no-image-available.svg',
  toDoFallbackImage: 'https://cdn.unhurd.co.uk/images/todo-unhurd-services.png',
  unhurdFallback: '/images/logos/unhurd-logo.png',
  profilePlaceholder: '/images/profile-placeholder.svg',
  largeTick: '/images/icons/large-tick.svg',
  advice: '/images/images/advice.svg',
  partyEmoji: '/images/icons/party-emoji.png',
  widgetImages: {
    playlistPitches: 'https://cdn.unhurd.co.uk/images/web/widgets/playlist-pitching.png',
    partnerPerks: 'https://cdn.unhurd.co.uk/images/web/widgets/partner-perks.png',
    fanHub: 'https://cdn.unhurd.co.uk/images/web/widgets/fan-hub.png',
    metaAds: 'https://cdn.unhurd.co.uk/images/web/widgets/meta-ads.png',
    tikTokAds: 'https://cdn.unhurd.co.uk/images/web/widgets/tiktok-ads.png',
    socialAds: 'https://cdn.unhurd.co.uk/images/web/widgets/social-ads.png',
    insights: 'https://cdn.unhurd.co.uk/images/web/widgets/insights.png',
    home: 'https://cdn.unhurd.co.uk/images/web/widgets/home.png',
    support: 'https://cdn.unhurd.co.uk/images/web/widgets/support.png',
    settings: 'https://cdn.unhurd.co.uk/images/web/widgets/settings.png',
    releaseCycles: 'https://cdn.unhurd.co.uk/images/web/widgets/release-cycles.png',
    profile: 'https://cdn.unhurd.co.uk/images/web/widgets/profile.png',
    aiGenerator: 'https://cdn.unhurd.co.uk/images/web/widgets/ai-generator.png',
  },
  releaseCycles: {
    noReleaseBackground: 'https://cdn.unhurd.co.uk/images/web/release-cycles/no-release-cycle-bg.png',
    releaseCycleBackground: 'https://cdn.unhurd.co.uk/images/web/release-cycles/release-cycle-bg.png',
    defaultReleaseArtwork: 'https://cdn.unhurd.co.uk/images/web/release-cycles/default-release-artwork.png',
    vinylCenter: 'https://cdn.unhurd.co.uk/images/web/release-cycles/vinyl-center.png',
    waves: 'https://cdn.unhurd.co.uk/images/web/release-cycles/waves.png',
  },
  placeholders: {
    playlistPitch: 'https://cdn.unhurd.co.uk/images/web/placeholder-images/playlisting-placeholder.png',
    playlistPitchMobile: 'https://cdn.unhurd.co.uk/images/web/placeholder-images/playlisting-placeholder-mobile.png',
    playlistPitchHomeBannerImage:
      'https://cdn.unhurd.co.uk/images/web/placeholder-images/playlist-pitch-home-image.png',
    metaAds: 'https://cdn.unhurd.co.uk/images/web/placeholder-images/meta-placeholder.png',
    tiktokAds: 'https://cdn.unhurd.co.uk/images/web/placeholder-images/tiktok-placeholder.png',
    releaseCycle: 'https://cdn.unhurd.co.uk/images/web/placeholder-images/release-cycle-placeholder-image.png',
    insights: 'https://cdn.unhurd.co.uk/images/web/placeholder-images/insights-placeholder-image.png',
    artistBio: 'https://cdn.unhurd.co.uk/images/web/placeholder-images/artist-bio.png',
    pressRelease: 'https://cdn.unhurd.co.uk/images/web/placeholder-images/press-release.png',
    basicInfo: 'https://cdn.unhurd.co.uk/images/web/placeholder-images/basic-info.png',
  },
  onboarding: {
    onboardingGraphic: 'https://cdn.unhurd.co.uk/images/web/onboarding/onboarding-graphic-desktop.mp4',
    onboardingGraphicMobile: 'https://cdn.unhurd.co.uk/images/web/onboarding/onboarding-graphic-mobile.mp4',
    reviewCards: 'https://cdn.unhurd.co.uk/images/web/onboarding/review-cards.png',
    waves: 'https://cdn.unhurd.co.uk/images/web/onboarding/unhurd-waves.png',
  },
};
